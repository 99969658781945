.input {
  display: flex;
  border-width: 1px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  background: var(--input-background);
  border-radius: var(--input-border-radius);
  border: var(--input-border);
  color: var(--input-color);
  font-family: var(--righteous-font);
  text-align: var(--input-text-align);
}

.input[data-invalid] {
  border-color: var(--sb-red);
}
