.queueBody {
  height: 100%;
  max-height: calc(var(--dialog-max-height) * 0.6);
  width: 100%;
  overflow-y: scroll;
}

.queueItem {
  width: 100%;
  padding: 10px 5px;
  display: flex;
  justify-content: space-between;
}

.queueControls {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  gap: 5px;
}

.voteButton {
  color: var(--color-text);
  transition:
    color 1.5s ease,
    stroke 1.5s ease;
}

.voteButton:disabled {
  cursor: pointer;
  color: var(--sb-orange);
  stroke: var(--sb-orange);
}
