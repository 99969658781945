.songContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.imageContainer {
  height: 3.5em;
  aspect-ratio: 1/1;
  width: auto;
  position: relative;
}

.image {
  object-fit: cover;
  border-radius: 15%;
}

.textContainer {
  height: 100%;
  width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding-left: 10px;
}

.textContainer > h1,
.textContainer > h2,
.textContainer > h3,
.textContainer > h4,
.textContainer > h5,
.textContainer > h6,
.textContainer > p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.button {
  width: 100%;
  border: none;
  border-radius: 6px;
  padding: 1rem 0.5rem 1rem 0.5rem;
  margin-bottom: 0.5rem;
}

.button:hover {
  transition: background-color 0.45s ease-in-out;
  background-color: #8d8c8c;
}
