.searchInput {
  background-color: transparent;
  text-align: left;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.searchResultsContainer {
  overflow-y: scroll;
  height: 20rem;
  width: 100%;
}

@media (min-width: 640px) {
  .searchResultsContainer {
    height: 30rem;
  }
}
